<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>客服管理</template>
      <template v-slot:secondMenu>创建客服</template>
    </breadcrumb-nav>
    <el-card style="margin: 10px 0px">
      <el-alert style="margin-bottom: 10px;" type="warning" title="注意：创建客服前请先选择店铺, 一个店铺一个人工客服，同时自动创建一个机器人客服。" :closable="false" show-icon></el-alert>
      <shop-select @shopChange="shopChange" :system="true"></shop-select>
      <el-button
        :disabled="isBtnDisabled"
        type="primary"
        icon="el-icon-plus"
        style="margin: 10px 0px"
        @click="showDialog"
      >添加
      </el-button>
    </el-card>
    <el-table style="width: 100%" border :data="list">
      <el-table-column type="index" label="序号" width="80px" align="center">
      </el-table-column>
      <el-table-column prop="imageUrl" label="图标" width="100px">
        <template slot-scope="{ row }">
          <img :src="row.imageUrl" alt="" style="width: 50px; height: 50px" />
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称">
      </el-table-column>
      <el-table-column prop="mobile" label="手机">
      </el-table-column>
      <el-table-column prop="password" label="密码">
      </el-table-column>
      <el-table-column prop="customerType" label="类型">
        <template slot-scope="scope">{{ scope.row.customerType | customerTypeLabel }}</template>
      </el-table-column>
      <el-table-column prop="prop" label="操作">
        <template slot-scope="{row}">
            <el-button
              type="warning"
              icon="el-icon-edit"
              size="mini"
              @click="updateCustomer(row)"
            >修改</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="form.id ? '修改客服' : '添加客服'"
      :visible.sync="dialogFormVisible"
    >
      <!-- form表单 :model属性，这个属性的作用是,把表单的数据收集到那个对象的身上 ，将来表单验证，也需要这个属性-->
      <el-form style="width: 80%" :model="form" :rules="rules" ref="form">
        <el-form-item label="名称" label-width="100px" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="手机" label-width="100px" prop="mobile">
          <el-input v-model="form.mobile"></el-input>
        </el-form-item>
        <el-form-item label="密码" label-width="100px" prop="password">
          <el-input v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item label="图标" label-width="100px" prop="icon">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleUploadSuccess"
            :headers="imgUploadHeaders"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addOrUpdate"
        >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BreadcrumbNav from '../common/BreadcrumbNav'
import ShopSelect from '../common/ShopSelect'
import { addCustomerRequest, updateCustomerRequest, getCustomerListRequest } from '../../network/customer'
export default {
  name: 'Customer',
  components: {
    BreadcrumbNav,
    ShopSelect
  },
  filters: {
    customerTypeLabel (value) {
      switch (value) {
        case 1:
          return '人工客服'
        case 2:
          return '机器人客服'
      }
    }
  },
  data () {
    return {
      shopId: -1,
      dialogFormVisible: false,
      form: {
        icon: '',
        name: '',
        password: '',
        mobile: '',
        customerType: 0,
        shopId: -1
      },
      uploadUrl: window.g.MEDIA_URL + '/upload',
      imgUploadHeaders: { // 上传图片控件的header
        Authorization: sessionStorage.getItem('token')
      },
      imageUrl: '',
      // 表单验证规则
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        mobile: [{ required: true, message: '请输入手机', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        icon: [{ required: true, message: '请选择图标' }]
      },
      // 总共数据条数
      total: 0,
      // 列表展示的数据
      list: []
    }
  },
  computed: {
    isBtnDisabled () {
      return this.shopId === -1 || this.total > 0
    }
  },
  methods: {
    shopChange (shopId) {
      console.log('-------this.shopId:', shopId)
      this.shopId = shopId
      this.form.shopId = shopId
      this.getList()
    },
    getList () {
      getCustomerListRequest({ shopId: this.shopId }).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取客服列表失败', 'error')
        }
        this.list = result.data.list
        for (const item of this.list) {
          item.imageUrl = window.g.MEDIA_DOWNLOAD_URL + item.icon
        }
        this.total = result.data.total
      })
    },
    updateCustomer (row) {
      console.log('update row:', row)
      this.dialogFormVisible = true
      this.form = { ...row }
      this.imageUrl = window.g.MEDIA_DOWNLOAD_URL + this.form.icon
    },
    showDialog () {
      this.dialogFormVisible = true
      this.form = { icon: '', name: '', password: '', mobile: '', shopId: this.shopId }
      this.imageUrl = ''
    },
    update2Srv () {
      updateCustomerRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改客服失败', 'error')
        }
        this.alertMessage('修改客服成功', 'success')
        this.getList()
      })
    },
    add2Srv () {
      addCustomerRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('添加客服失败', 'error')
        }
        this.alertMessage('添加客服成功', 'success')
        this.getList()
      })
    },
    addOrUpdate () {
      this.$refs.form.validate(valid => {
        if (!valid) return
        this.dialogFormVisible = false
        console.log('this.form:', this.form)
        if (this.form.id) {
          this.update2Srv()
        } else {
          this.add2Srv()
        }
      })
    },
    handleUploadSuccess (res, file) {
      console.log('handleUploadSuccess res:', res)
      this.form.icon = res.data.list[0].mediaId
      this.imageUrl = window.g.MEDIA_DOWNLOAD_URL + this.form.icon
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
