<template>
  <el-form :inline="true" class="demo-form-inline" :disabled="forbidden" :setDefault="setDefault" :system="system">
    <el-form-item label="商品店铺">
      <el-select
        :value="value"
        placeholder="请选择店铺"
        v-model="shopId"
        @change="shopChange"
        clearable="clearable"
      >
        <el-option
          :label="item.name"
          :value="item.shopId"
          v-for="item in shopList"
          :key="item.shopId"
        ></el-option>
        <div style="float: right;margin-right:10px;padding-bottom: 10px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="listQuery.page"
            :page-sizes="[20, 50]"
            :page-size="listQuery.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
import { getShopList, getShop } from '../../network/shop'

export default {
  name: 'ShopSelect',
  model: {
    prop: 'value',
    event: 'shopChange'
  },
  props: ['value', 'forbidden', 'setDefault', 'system', 'clearable'],
  data () {
    return {
      shopList: [],
      shopId: '',
      listQuery: {
        page: 1,
        size: 20
      },
      total: 0
    }
  },
  // 组件挂载完毕：向服务器发请求，获取相应分类的数据
  mounted () {
    // 获取分类的数据
    this.listShop()
  },
  methods: {
    // 获取分类列表
    listShop () {
      const params = { page: this.listQuery.page, size: this.listQuery.size }
      if (this.system) {
        params.shopType = 0
      }
      getShopList(params).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('获取分类列表失败', 'error')
        }
        this.total = res.data.data.total
        this.shopList = res.data.data.list
        /* if (this.system) {
          this.shopList.unshift({ shopId: 0, name: '平台' })
        } */
        if (this.shopList.length > 0 && this.setDefault) {
          this.shopId = this.setDefault
        }
      })
    },
    shopChange () {
      this.$emit('shopChange', this.shopId)
    },
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.listShop()
    },
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.listShop()
    },
    initShop (shopId) {
      this.shopId = shopId
      getShop(this.shopId).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('获取店铺信息失败', 'error')
        }
        const shop = res.data.data
        var hasExsit = false
        for (const item of this.shopList) {
          if (item.shopId === shop.shopId) {
            hasExsit = true
            break
          }
        }
        if (!hasExsit) {
          this.shopList.push(shop)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
