import { request } from './request'

// 添加客服
export function addCustomerRequest (params) {
  return request({
    baseUrl: window.g.CUSTOMER_URL,
    url: '/customer/add',
    method: 'post',
    data: { ...params }
  })
}

// 更新客服
export function updateCustomerRequest (params) {
  return request({
    baseUrl: window.g.CUSTOMER_URL,
    url: '/customer/update',
    method: 'post',
    data: { ...params }
  })
}

// 获取客服列表
export function getCustomerListRequest (params) {
  return request({
    baseUrl: window.g.CUSTOMER_URL,
    url: '/customer/list',
    method: 'get',
    params
  })
}

export function getKnowledgeListRequest (params) {
  return request({
    baseUrl: window.g.CUSTOMER_URL,
    url: '/knowledge/list',
    method: 'get',
    params
  })
}

export function addKnowledgeRequest (params) {
  return request({
    baseUrl: window.g.CUSTOMER_URL,
    url: '/knowledge/add',
    method: 'post',
    data: { ...params }
  })
}

export function updateKnowledgeRequest (params) {
  return request({
    baseUrl: window.g.CUSTOMER_URL,
    url: '/knowledge/update',
    method: 'post',
    data: { ...params }
  })
}

export function delKnowledgeRequest (id) {
  return request({
    baseUrl: window.g.CUSTOMER_URL,
    url: `/knowledge/${id}`,
    method: 'delete'
  })
}

export function SortKnowledgeRequest (data) {
  return request({
    baseUrl: window.g.CUSTOMER_URL,
    url: '/knowledge/sort',
    method: 'post',
    data
  })
}
